@import '../../utils/stylesVariables';

.filter-modal-wrapper {
    .ant-modal {
        width: 620px !important;
        &-title {
            font-size: 28px;
            font-weight: bold;
            font-family: futura-pt, sans-serif;
        }
    }
    .filter-item {
        &__title {
            color: $primary;
            font-weight: 600;
        }
    }

    .filter-item {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
    }

    .ant-popover-inner {
        max-height: 60vh;
        overflow: auto;
    }

    .disabled-filter {
        background-color: #cf09208f;
    }

    .ant-input-number {
        line-height: 27px !important;
    }

    input.ant-input-number-input {
        text-overflow: ellipsis;
    }
    .filter-item {
        & > div {
            display: inline-block;
            vertical-align: top;
        }
        .filter-title {
            width: 150px;
            font-weight: bold;
            white-space: nowrap;
            color: black;
        }
        .filter-content {
            width: calc(100% - 150px);
            .ant-select {
                min-width: 150px;
                .ant-select-selection:hover {
                    border-color: #40a9ff;
                }
            }
        }
        .games-selector {
            width: 100%;
            border: 1px solid $grey;
            border-radius: 12px;
            max-height: 146px;
            overflow: auto;
            .ant-select-selector {
                background-color: transparent;
                .ant-select-selection-item {
                    border-radius: 10px;
                    text-transform: uppercase;
                }
            }
        }
    }
    .ant-btn {
        border-radius: 24px;
        height: 40px;
    }
    .ant-btn.ant-btn-dashed {
        cursor: auto;
    }
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus {
        color: #d9d9d9;
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-btn-danger {
        color: #fff;
        background-color: #cf0920;
        border-color: #cf0920;
    }
    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: #fff;
        background-color: #cf3548;
        border-color: #cf3548;
    }
}

.filters {
    .ant-tag {
        color: black;

        &.reset {
            font-weight: bold;
        }

        .anticon-close {
            font-size: 13px;
            color: black;
            position: relative;
        }

        &.filter-tag {
            height: 32px;
            border-radius: 16px;
            margin-right: 8px;
            line-height: 32px;
            font-size: 11px;
            border: 0;
            letter-spacing: 0.5px;
            padding: 0 16px;
            background-color: #fff;
            &.reset,
            &.show-all {
                cursor: pointer;
            }
        }
    }
}

.currency-checkbox-wrapper {
    .currency-checkbox {
        .ant-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            table {
                width: 100%;
            }
            .currency-row {
                margin-bottom: 10px;
                .currency-item {
                    text-align: left;
                }
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: $primary;
                border-color: $primary;
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner,
            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: $primary;
            }
            .ant-checkbox-checked::after {
                border: none;
            }
        }
        .ant-checkbox-group-item {
            width: 20%;
        }
        .currency-checkbox-button {
            display: flex;
            height: 21px;
            margin-top: 16px;

            .see-showMore,
            .select-showMore,
            .reset {
                color: var(--accent-color);
                cursor: pointer;
                margin-right: 30px !important;
                &.disable {
                    cursor: default;
                    color: $primary300;
                }
            }
        }
    }

    //.ant-checkbox-wrapper-disabled {
    //  .ant-checkbox-disabled {
    //    color: #B2B2B2;
    //    border:none !important;
    //    .ant-checkbox-input {
    //      color: #B2B2B2;
    //      border:none !important;
    //    }
    //    .ant-checkbox-inner {
    //      background: #B2B2B2 !important;
    //      color:white;
    //      &::after {
    //        border-color:white;
    //      }
    //    }
    //
    //  }
    //}

    //.ant-checkbox {
    //  border: 1px solid black;
    //  border-radius: 2px;
    //
    //
    //  .ant-checkbox-input {
    //    border: 1px solid black;
    //    border-radius: 2px;
    //  }
    //
    //  &:hover {
    //    border-color: black !important;
    //  }
    //
    //  &::after {
    //    border-color: black !important;
    //  }
    //
    //  .ant-checkbox-inner {
    //    border: none !important;
    //  }
    //
    //  &.ant-checkbox-checked {
    //    &::after {
    //      border-color: black !important;
    //    }
    //
    //    .ant-checkbox-inner {
    //      background-color: black;
    //      border: none !important;
    //    }
    //  }
    //}

    .currency-row {
        display: flex;
        flex-direction: column;
    }

    .currencies_tooltip {
        position: relative;
        margin: 20px 0;
        padding: 15px;
        border-radius: 10px;
        background-color: #eaedf2;
        &::before {
            content: '';
            position: absolute;
            left: 96px;
            top: -20px;
            display: block;
            border: 10px solid transparent;
            border-bottom: 10px solid #eaedf2;
        }
        .container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .primary_color {
                color: $primary;
                font-weight: bold;
            }
            &_btn {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
        .tooltip_sign {
            margin: 0 20px 0 0;
            font-size: 18px;
            color: $primary;
        }
        .ant-checkbox-wrapper {
            &:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner,
            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: $primary;
            }
            .ant-checkbox {
                margin-right: 13px;
            }
            span {
                color: $primary300;
            }
        }
        .ant-checkbox-wrapper-checked {
            span {
                color: $primary;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $primary;
            border-color: $primary;
        }
        .btn {
            padding: 0 40px;
            border-radius: 20px;
            background-color: $white;
            color: $red;
            cursor: pointer;
        }
    }
}

#filter-button {
    height: 40px;
    margin-left: 16px;
    background-color: white;
    color: rgba(0, 0, 0, 0.65);
    border: 0;
    box-shadow: none;
}

.switcher {
    &__wrapper {
        width: 224px;
        height: 32px;
        position: relative;
        display: flex;
        border-radius: 20px;
        background-color: #eaedf2;
        cursor: pointer;
    }
    &__scrolled-block {
        width: 112px;
        height: 32px;
        border-radius: 20px;
        position: absolute;
        background-color: #cf0920;
        transition: 0.3s;
        &.right {
            transform: translateX(112px);
        }
    }
    &__option {
        width: 112px;
        z-index: 10;
        line-height: 32px;
        display: flex;
        justify-content: center;
        transition: 0.4s;
        color: $primary300;
        &:not(.active):hover {
            color: #cf0920;
        }
        &.active {
            color: white;
        }
    }
}
