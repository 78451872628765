@import '../../utils/stylesVariables';

.main-menu {
    //display: flex;
    //margin-left: -288px;
    //flex-direction: column;
    //align-items: center;
    //width: 288px;
    //height: 100vh;
    //padding: 16px 0 16px 0;
    //overflow: hidden;
    //transition: .3s;
    //box-shadow: none;

    &__title {
        color: var(--accent-color);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.75px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        transition: 0.2s;
        white-space: nowrap;

        &.minified {
            font-size: 25px;
            transition: 0.2s;
            text-align: center;
            letter-spacing: 1px;
            font-weight: 600;
        }
    }

    &__fold-button {
        top: 24px;
        left: 20px;
        cursor: pointer;
        position: fixed;
        z-index: 4;

        &.closed {
            animation-timing-function: ease;
            animation-direction: reverse;
            animation-duration: 0.4s;
            animation-name: hideButton;
            animation-fill-mode: forwards;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__logo {
        bottom: 65px;
        position: absolute;
        transition: 0.2s;
        overflow: hidden;
    }

    &.visible {
        margin-left: 0;
        box-shadow: 0 2px 4px 0 $primary300;
    }

    i {
        font-size: 20px;
        color: $primary;
    }
}

.layout {
    min-height: 100vh;
    height: 100vh;
    .ant-layout-sider-children {
        position: fixed;
        width: inherit;
        background-color: white;
    }
}

.ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
        a {
            color: $primary;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.ant-menu-submenu.ant-menu-submenu-vertical {
    .ant-menu-submenu-title {
        a {
            color: $primary;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0;
    width: 100%;
}

.ant-layout-sider {
    background: white;
    position: relative;
}
.menu-item__title {
    font-size: 16px;
}

@keyframes hideButton {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ant-menu-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ant-menu-submenu-title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

@import 'MainMenuMedia';
